.create-form {
  background-color: var(--bg-color);
  padding: 3vw 0px !important;
}

.create-form label,
.create-form label {
  font-family: var(--font-brandon-regular);
  color: var(--form-label-color);
  font-size: var(--font-size-20);
  line-height: var(--line-height-29);
  font-weight: normal;
  margin-bottom: 2px;
}

.create-form input,
.create-form .react-select-prefix__control,
.create-form select {
  border-radius: 4px;
}

.create-form .react-select-prefix__control,
.user-roles .react-select-prefix__control {
  border: 1px solid #ced4da !important;
}

.create-form input:focus,
.create-form select:focus,
.user-roles .select__control--is-focused,
.date-container input:focus {
  border-color: var(--input-field-border) !important;
}

/* Manage Users table */
.manage-users-table td:nth-child(1),
.manage-users-table th:nth-child(1) {
  width: 15%;
}

.manage-users-table td:nth-child(2),
.manage-users-table th:nth-child(2) {
  width: 25%;
}

.manage-users-table td:nth-child(3),
.manage-users-table th:nth-child(3) {
  width: 25%;
}

.manage-users-table td:nth-child(4),
.manage-users-table th:nth-child(4) {
  width: 20%;
}

.manage-users-table td:nth-child(5),
.manage-users-table th:nth-child(5) {
  width: 15%;
}

.manage-users-action svg:hover path {
  fill: var(--raven-red);
}

.create-role-tabs .nav-item {
  float: left;
  height: var(--line-height-48);
  line-height: var(--line-height-48);
}

.create-role-tabs .nav-item .nav-link {
  color: var(--raven-gray);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-20);
  line-height: var(--line-height-48);
}

.create-role-tabs .nav-item .nav-link.active {
  background-color: transparent !important;
  color: var(--raven-red);
}

.create-role-tabs .nav-item .nav-link:hover {
  color: var(--raven-red);
}

/* Manage roles table */
.manage-roles-table table tbody td:nth-child(1) {
  width: 50%;
}

.manage-roles-table table tbody td:nth-child(2) {
  width: 20%;
}

.manage-roles-table table tbody td:nth-child(3) {
  width: 20%;
}

.manage-roles-table table tbody td:nth-child(4) {
  width: 20%;
}

.page-links ,.page-links label {
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-22);
  line-height: var(--line-height-48);
  color: var(--raven-gray);
}

.link-border {
  border-bottom: 2px solid var(--dashboard-link-border);
}

.page-links:hover ,.page-links label:hover{
  color: var(--raven-red);
  cursor: pointer;
}

.page-links:hover svg path {
  fill: var(--raven-red);
}

.page-links svg {
  width: 2.188rem;
  height: 2.188rem;
  margin-right: 0.5rem;
}

.user-roles .form-control {
  border: 0 !important;
  padding: 0 !important;
}

.user-roles input[type="text"] {
  min-height: 15px !important;
}

.user-roles .select__control {
  min-height: 45px;
}

.user-roles .select__control:hover {
  border-color: transparent;
}

.user-tabs .tab-btn {
  color: var(--raven-gray);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-20);
}

.user-tabs .tab-btn.active {
  color: white;
  background-color: var(--raven-red) !important;
  text-align: center !important;
  border-radius: 5px;
  padding: 5px 10px;
}

.user-tabs button:focus{
  box-shadow: none !important;
}

.manage-users-table {
  min-height: calc(100vh - 500px);
}

.asterick {
  color: var(--raven-red);
}

.manage-translation-table table tbody td:nth-child(1) {
  width: 20%;
}

.manage-translation-table table tbody td:nth-child(2) {
  width: 20%;
}

.manage-translation-table table tbody td:nth-child(3) {
  width: 20%;
}

.manage-translation-table table tbody td:nth-child(4) {
  width: 20%;
}

.manage-translation-table table tbody td:nth-child(5) {
  width: 16%;
}
