.manage_mailtemp_table tr td:nth-child(1) {
  width: 10%;
}
.manage_mailtemp_table tr td:nth-child(2) {
  width: 24%;
}
.manage_mailtemp_table tr td:nth-child(3) {
  width: 20%;
}
.manage_mailtemp_table tr td:nth-child(3) {
  width: 20%;
}

.manage_mailtemp_table tr td:nth-child(4) {
  width: 20%;
}

.manage_mailtemp_table tr td:nth-child(5) {
  width: 13%;
}

.manage_mailtemp_table tr td:nth-child(6) {
  width: 13%;
}

.file_input svg path {
  fill: white;
}

.file_input {
  max-width: 200px;
  width: 200px;
}

.import_error_text {
  width: 200px;
  font-size: 0.8rem;
  text-align: center;
}
