/* .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
} */

.mediamodalimg {
  height: 45vh;
  object-fit: contain;
  max-height: 50vh;
}
.mediamodaltagclosebtn svg {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.mediamodaltagclosebtn svg:hover path {
  fill: var(--raven-red);
}

.mediamodaltagclosebtn {
  right: 10px;
  top: -12px;
}

.hashtagestitle{
  width: 8vw;
  display: block;
  text-align: center;
}

.tagsuggestions{
  height: 130px;
  overflow-y: scroll;
}