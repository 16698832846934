.maincolor_box {
  flex-direction: column;
  cursor: pointer;
  max-width: 150px;
}

.label {
  font-size: var(--font-size-18);
  font-family: var(--font-brandon-medium);
  color: var(--color-black);
}

.color_name {
  font-size: var(--font-size-18);
  color: var(--tab-text-color);
}

.selectedcolorbox {
  display: contents;
}

.closebtn svg{
  position: absolute;
  top: -4px;
  right: -5px;
  background: white;
  margin: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid white;
}

.closebtn svg:hover path{
  fill: var(--raven-red);
}

.addbtn {
  background-color: var(--raven-red);
  border: 1px solid var(--raven-red);
  color: var(--color-white);
  padding: 0rem 2rem;
  border-radius: 4px;
  height: 44px;
  font-size: var(--font-size-18);
}

.maincolor_box:nth-child(1) {
  margin-left: 0 !important;
}

.color_label {
  border: 1px solid red !important;
}
.colorsearchbox {
  width: 250px !important;
}

.colorsearchbox svg {
  margin: 0px 14px;
}

.colorsearchbox input:focus-visible {
  outline: none;
}
