.admincard {
    width: calc(100%/5);
    display: flex;
    padding-right: 3vw;
    margin-top: 10px;
    padding-bottom: 3vw;
}

.admincard :hover {
    color: var(--raven-red) !important;
}

.admincard :hover svg path {
    fill: var(--raven-red);
}

.admincard svg {
    width: 4vw;
    height: 4vw;
}


.cards {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 4px;
    flex: 1 1 auto;
    padding: 3vw;
}

.cards p {
    font-family: var(--font-brandon-medium);
    /* font-size: var(--font-size-24); */
    font-size:1.3vmax;
    margin-bottom: 0;
}

.cards:hover {
    transform: scale(1.02);
    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0px 0px 7px 1px #0000009c;
}

/* @media (max-width:1400px) {
    .cards p {
        font-size: calc(var(--font-size-24) * 1400/ 1920);
    }
} */

/* @media (max-width:1200px) {
    .admincard {
        width: calc(100%/3);
    }
}  */