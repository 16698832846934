.page_title {
  font-family: var(--font-brandon-bold);
  font-size: var(--font-size-33);
  position: sticky;
  top: 96px;
  z-index: 1;
  color: var(--color-black);
}

.label {
  font-size: var(--font-size-18);
  font-family: var(--font-brandon-medium);
  color: var(--color-black);
}

.skuimg {
  height: 10vw;
}

.sku_title {
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-33);
  color: var(--color-black);
}

.check_btn {
  background-color: var(--raven-red);
  border: 1px solid var(--raven-red);
  color: var(--color-white);
  padding: 0rem 2rem;
  border-radius: 4px;
  height: 45px;
  font-size: var(--font-size-22);
}

.clear_btn {
  background-color: var(--raven-gray);
  border: 1px solid var(--raven-gray);
  color: var(--color-white);
  padding: 0rem 2rem;
  border-radius: 4px;
  height: 45px;
  font-size: var(--font-size-22);
}

.check_btn:hover {
  background-color: var(--color-white);
  color: var(--raven-red);
  transition: all 0.5s ease;
}

.clear_btn:hover {
  background-color: var(--color-white);
  color: var(--raven-gray);
  transition: all 0.5s ease;
}

.data_entry_left_block img {
  object-fit: contain;
  width: 100%;
}

.sku_detail_card {
  flex: 1 1 400px;
  max-width: 400px;
}

.sku_detail_card img {
  height: 211px !important;
}

.sku_detail_card p {
  color: var(--raven-gray);
  font-size: 18px;
  margin-bottom: 5px;
}

.sku_detail_card :nth-child(2) {
  font-family: var(--font-brandon-bold);
  font-size: 20px !important;
}

.overview_label {
  color: var(--color-black);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-18);
}

.form_content:nth-child(odd) {
  padding-right: 4rem;
}

.form_content:nth-child(even) {
  padding-left: 4rem;
}

@media (max-width: 1600px) {
  .form_content:nth-child(odd) {
    padding-right: 1rem;
  }

  .form_content:nth-child(even) {
    padding-left: 1rem;
  }
}

@media (max-width: 1780px) {
  .sku_detail_card {
    flex: 1 1 370px;
    max-width: 370px;
  }
}

@media (max-width: 1200px) {
  .sku_detail_card {
    flex: 1 1 360px;
    max-width: 360px;
  }
}

/* manage data entry overview */
.create_btn svg {
  width: 26px;
  height: 26px;
  margin-bottom: 2px;
}

.create_btn svg path {
  fill: var(--color-white);
}

.create_btn:hover svg path {
  fill: var(--raven-red);
}

.manage_title {
  font-size: var(--font-size-26);
  font-family: var(--font-brandon-medium);
}

.add_btn svg {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.add_btn svg:hover path {
  fill: var(--raven-red);
}

.activeTab {
  color: var(--raven-red);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-18);
  background-color: var(--raven-red);
  text-decoration: underline;
}
