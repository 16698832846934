.imagage_block {
  display: block;
  z-index: 9999;
}

.accessthree{
  font-family: var(--font-brandon-bold);
  color: #000000AD;
  font-size: 11vw;
}

.accessDenied{
  color: #000000AD;
  font-size: 2vw;
  font-family: var(--font-brandon-medium);
}

.pagecontent {
  color: #000000AD;
  font-size: 1.3vw;
  font-family: var(--font-brandon-medium);
}