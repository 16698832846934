.page_title {
  font-family: var(--font-brandon-bold);
  font-size: min(40px, 2.063vw);
  position: sticky;
  top: 96px;
  z-index: 1;
  color: var(--color-black);
}

.shipmentcardouter {
  width: calc(100% / 4);
  padding: 10px;
}

/* Target the first div in each row */
.shipmentcardouter:nth-child(4n - 3) {
  padding-left: 0;
}

/* Target the last div in each row */
.shipmentcardouter:nth-child(4n) {
  padding-right: 0;
}

.shimpmentlogo img {
  height: 4.25rem;
  width: 8.5rem;
  object-fit: contain;
  border-radius: 8px;
}

.shipmenttimer {
  min-width: 21rem;
}

.shimpmentlogo {
  min-width: 10rem;
}

.shipmenttimer {
  font-family: var(--font-brandon-regular) !important;
}

.sortingheading {
  font-size: min(var(--font-size-22), 1.8vw);
  font-family: var(--font-brandon-medium);
  color: var(--label-color);
  flex: 1;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.countnum {
  font-size: var(--font-size-40);
  font-family: var(--font-brandon-medium);
  color: var(--label-color);
}

.plus_minus svg:hover path,
.plus_minus svg:hover {
  fill: var(--raven-red);
  cursor: pointer;
}

.timer {
  color: var(--raven-gray);
  font-size: var(--font-size-22);
  width: 8rem;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
}

.strain_body {
  height: calc(100vh - 200px);
}

.shipment_header_icons svg {
  height: 40px !important;
}

.washingicon svg {
  height: 4vw;
  width: 4vw;
}

.label {
  font-family: var(--font-brandon-bold);
  font-size: min(36px, 1.8vw);
  color: var(--color-black);
}

.box {
  position: relative;
  cursor: pointer;
  box-shadow: 0px 1px 6px #00000029;
}
.shipment_card_main_box {
  width: calc(100% / 6);
  padding: 20px;
}

.box:hover {
  transform: scale(1.02);
  transition: all 0.5s;
  cursor: pointer;
  box-shadow: 0px 0px 7px 1px #0000009c;
}

.box_inner {
  /* width: 12.5rem; */
  height: 12.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.strain_treatmentbox .box_inner {
  height: 100% !important;
}

.strain_treatmentbox .titel {
  flex: 1;
}

.box:before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
}

.count_circel {
  position: absolute;
  top: -17px;
  right: -14px;
  border: 3px solid var(--raven-red);
  border-radius: 50%;
  height: 3.438rem;
  width: 3.438rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  outline: 7px solid white;
}

.count_circel svg {
  height: 2vw !important;
  width: 2vw !important;
}

.title {
  font-size: var(--font-size-18);
  color: var(--shimpment_text_color);
  font-family: var(--font-brandon-medium);
  line-height: 1;
}

.title_below {
  font-size: var(--font-size-18);
  color: var(--raven-red);
  font-family: var(--font-brandon-medium);
}

.second svg path,
.second .title {
  fill: var(--raven-red) !important;
  color: var(--raven-red) !important;
}

.second:before {
  --g: #0000 calc(98% - 3px), #000 calc(100% - 3px) 98%, #0000;
  --mask: radial-gradient(0px at 0 40px, var(--g)) 0 -40px,
    radial-gradient(38px at 92.5% 12px, var(--g)),
    conic-gradient(from -90deg at right 3px bottom 3px, #0000 0 90deg, #000 0)
      100% 100% / calc(100% - 0px + 10px) calc(100% - 40px + 10px) no-repeat,
    linear-gradient(#000 0 0) left top/78.5% 3px no-repeat,
    linear-gradient(#000 0 0) left/3px calc(100% - 0px + 3px) no-repeat;
  background: linear-gradient(var(--raven-red), var(--raven-red));
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.cus_small_card {
  /* flex: 1 1 100px; */
  margin-right: 3rem;
  width: 200px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 1px 6px #00000029;
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-18);
}

.cus_small_card:hover {
  /* flex: 1 1 100px; */
  transform: scale(1.02);
  transition: all 0.5s;
  box-shadow: 0px 0px 7px 1px #0000009c;
}

.custable thead tr {
  outline: none !important;
}

.custable {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.custable thead tr th:nth-child(1) {
  width: 35% !important;
}

.custable thead tr th:nth-child(2) {
  width: 25% !important;
}

.custable thead tr th:nth-child(3) {
  width: 40% !important;
}

.custable thead::after {
  display: none !important;
}

.custable tr {
  outline: 1px solid #54596066 !important;
  text-align: center;
  color: var(--shimpment_text_color);
}

.delete_icon svg {
  width: 30px;
  height: 30px;
}

.shipment_title {
  color: var(--label-color);
}

.success_box {
  background: var(--sort_success_bg);
}

.success_box_content {
  color: var(--label-color);
  font-size: var(--font-size-22);
  font-family: var(--font-brandon-medium);
}

.success_box_content:nth-child(odd) {
  padding-right: 5rem;
}

.success_box_content:nth-child(even) {
  padding-left: 5rem;
}

.sort_popup_icons svg {
  width: 25px;
  height: 25px;
}

.sort_popup_icons svg path {
  fill: white;
}

.sort_popup_icons svg:hover path,
.plus_minus svg:hover {
  fill: var(--raven-red);
  cursor: pointer;
}

.ongoing_shipment_mainblock {
  height: calc(100vh - 186px);
}

.ongoing_shipment_block {
  flex: 1;
  overflow-y: auto;
}

.comment_block {
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.countInput {
  width: 75px;
  font-size: 32px;
  text-align: center;
}
.disableCountInput {
  width: 135px !important;
}

.bardownload svg:hover path {
  fill: var(--raven-red);
}

.merchpopblock {
  width: calc(100% / 6);
}

.merch_dimension_blocks {
  width: calc(100% / 6);
  color: var(--raven-gray);
  cursor: pointer;
  line-height: initial;
}

.merchpopblock svg {
  width: 4.5vw !important;
  height: 4.5vw !important;
}

.merchpopblockselected {
  background: var(--raven-red);
  color: white;
}

.merchpopblockselected svg path,
.merchpopblockselected svg g rect {
  fill: white;
}

/* comments start*/

.commentstagename {
  font-size: 0.9vw;
}

.commentbox {
  height: 16vw;
  overflow-y: auto;
  color: initial;
}

.commentedbyname {
  max-width: 63%;
}

.commentedbydate {
  font-size: 0.65vw;
}

.commenttext {
  font-size: 1vw;
}

.commentcounts {
  font-size: 13px;
  background: #789070;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  border: 1px solid white;
  align-items: center;
  right: -8px;
  color: white;
  top: -9px;
}

.comment_btn {
  background-color: var(--raven-red);
  border: 1px solid var(--raven-red);
  color: var(--color-white);
  padding: 0rem 1rem;
  border-radius: 4px;
  font-size: var(--font-size-20);
}

.comment_btn:hover {
  background-color: white;
  border: 1px solid var(--raven-red);
  color: var(--raven-red);
}

/* comments ends*/
/* photo studio starts */
.photostudiotimer {
  font-size: 4vw;
  letter-spacing: 0.3cm;
}

/* .photoimg img {
  height: 29vh;
} */

/* pre publish starts */

.prepublish_card_block {
  margin: unset !important;
  width: calc(100% / 5);
  text-align: center;
  /* cursor: pointer; */
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-18);
}

.prepublish_card svg {
  height: 4.5vw !important;
  width: 4.5vw !important;
}

.prepublish_card,
.prepublish_card_success {
  margin: unset !important;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 1px 6px #00000029;
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-18);
}

.prepublish_card_success:hover,
.prepublish_card:hover {
  transform: scale(1.02);
  transition: all 0.5s;
  box-shadow: 0px 0px 7px 1px #0000009c;
}

.prepublish_card:hover svg path,
.prepublish_card:hover svg g rect,
.prepublish_card:hover p {
  fill: var(--raven-red);
  color: var(--raven-red);
}

.prepublish_card_selected svg path {
  fill: var(--raven-red);
}

.forwardicon svg:hover path {
  fill: var(--raven-red);
}

.succesicon svg,
.forwardicon svg {
  height: 35px !important;
  width: 35px !important;
}
.prepublish_card_selected {
  border: 1px solid var(--raven-red) !important;
}

.product_image {
  height: 5vw;
  min-height: 20px !important;
  width: 100%;
  object-fit: contain;
}

/* internal laundary */
.move_selected,
.move_selected svg path {
  color: var(--raven-red) !important;
  fill: var(--raven-red);
  border: 2px solid var(--raven-red);
}

.publishingCards {
  height: calc(100vh - 360px);
  overflow-y: auto;
}

/* manage shipment */
.printbarcodeicon svg path {
  fill: rgba(84, 89, 96, 0.35);
}
.printbarcodeicon svg {
  width: 30px !important;
  height: 30px !important;
}

@media (max-width: 1400px) {
  .ongoing_shipment_mainblock {
    height: calc(100vh - 173px);
  }

  .sortcounter .category_icon svg {
    width: 140px;
  }

  .box_inner {
    height: 11.5rem;
  }

  .prepublish_card_block {
    width: calc(100% / 4);
  }

  .merch_dimension_blocks,
  .merchpopblock {
    width: calc(100% / 5);
  }

  .shipment_card_main_box {
    width: calc(100% / 5);
    padding: 15px;
  }

  .brand_partner_dashboard_body {
    height: calc(100vh - 200px) !important;
  }
}

@media (max-width: 1200px) {
  .box_inner {
    height: 10.5rem;
  }

  .prepublish_card_block {
    width: calc(100% / 3);
  }

  .shipment_card_main_box {
    width: calc(100% / 4);
  }

  .brand_partner_dashboard_body {
    height: calc(100vh - 137px) !important;
  }
}

.blur .count_circel,
.blur .box_inner,
.blur:before {
  filter: blur(1.5px);
}

.brand_partner_dashboard_body {
  height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}

/* sort category starts*/

.config_sort_category {
  width: calc(100% / 5);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.config_sort_category svg {
  width: 100% !important;
}
