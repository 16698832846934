.masterselect input[type="text"] {
    min-height: 33px;
}

.masterdata_input {
    width: 280px;
}

.button_icon svg {
    height: 3rem;
    width: 3rem;
}

.button_icon svg path:hover,
.close_btn svg path:hover {
    fill: var(--raven-red);
}

.close_btn svg {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.preview_image{
    height: 13rem;
    padding: 2px;
    width: 100%;
    object-fit: contain;
}

.helptextdiv {
    height: 12px !important;
}
