.label {
  font-size: var(--font-size-18);
  font-family: var(--font-brandon-medium);
  color: var(--color-black);
}

.color_btn {
  background-color: #54596099;
  color: white;
  padding: 1rem 2rem;
  font-size: var(--font-size-18);
  /* width: 13rem; */
  text-align: center;
  cursor: pointer;
}

.bpsize_btn {
  width: calc(100% / 6);
}

.previewsizebtn {
  width: calc(100% / 6);
}

.sizepreviewblock {
  width: 150px;
}
.sizepreviewblock:last-child {
  margin: 0px !important;
}

.add_btn:hover path,
.selected_close_btn svg:hover path {
  fill: var(--raven-red);
}

.sizeEdit svg {
  height: 15px !important;
  width: 15px !important;
}

.accheader button {
  background: var(--bg-color);
  font-size: var(--font-size-18);
  font-family: var(--font-brandon-medium);
  color: var(--color-black);
}

.accheader button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.accheader button:not(.collapsed) {
  color: black !important;
  background-color: var(--bg-color) !important;
  /* box-shadow: inset 0 -1px 0 rgba(0,0,0,.125); */
}

.file_input {
  width: 100%;
  padding: 10px;
  background-color: #dddedf;
  border-radius: 4px;
  color: var(--raven-gray);
  font-family: var(--font-brandon-regular);
  font-size: var(--font-size-20);
}

.colorbox div {
  width: 100% !important;
}

.colorbox div div div div div div div div {
  display: flex;
}

.colorbox span {
  width: 100% !important;
}

.colorbox span div {
  width: 100px !important;
  height: 100px !important;
}

.sizeblock {
  background-color: var(--tab-color);
  /* width: 130px; */
  padding: 23px 0px;
  color: var(--tab-text-color);
  flex: 1;
}

.resalepricetabel tr {
  border: none !important;
}

.logoimg {
  height: 20rem;
  object-fit: contain;
}

.activebrandpartnerselect {
  background: var(--raven-red) !important;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activebrandpartnerselectBorder {
  border: 1px solid var(--raven-red);
}

.selected_close_btn {
  top: -12px !important;
  right: -8px;
}

.close_btn svg path:hover {
  fill: var(--raven-red);
}

.selected_close_btn svg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgb(240, 240, 240);
  border: 1px solid white;
}

.add_btn svg {
  height: 58px !important;
  width: 58px !important;
}

.logo_oreview_first {
  height: 13rem;
  padding: 2px;
  width: 100%;
  object-fit: contain;
}

.form_content:nth-child(odd) {
  padding-right: 4rem;
}

.form_content:nth-child(even) {
  padding-left: 4rem;
}

@media (max-width: 1400px) {
  .bpsize_btn {
    min-width: calc(100% / 5);
  }

  .previewsizebtn {
    width: calc(100% / 4);
  }

  .form_content:nth-child(odd) {
    padding-right: 3rem;
  }

  .form_content:nth-child(even) {
    padding-left: 3rem;
  }

  .logoimg {
    height: 15rem;
    object-fit: contain;
  }
}

@media (max-width: 1200px) {
  .bpsize_btn {
    min-width: calc(100% / 4);
  }

  .form_content:nth-child(odd) {
    padding-right: 1rem;
  }

  .form_content:nth-child(even) {
    padding-left: 1rem;
  }
}