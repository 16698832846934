.color_btn {
  width: 8rem;
  padding: 0.8rem 0.7rem;
  color: var(--raven-gray);
  border: 1px solid var(--raven-gray);
  cursor: pointer;
  line-height: initial;
  text-align: center;
  max-width: 8rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
}

.color_btn:hover {
  border: 1px solid var(--raven-gray);
  background-color: var(--raven-gray);
  color: white;
}

.activeItem {
  color: white !important;
  background: var(--raven-red) !important;
}

.min_height {
  height: 32vh;
  overflow-y: auto;
  font-size: var(--font-size-18);
}

.select_vendor_pop_body {
  height: 63vh;
  overflow-y: auto;
  font-size: var(--font-size-18);
}

.qaissues_box {
  flex: 1;
  overflow: auto;
}

.qaissuesblock {
  min-height: 176px;
}

.qclabel {
  font-size: 1.3vw;
  font-family: var(--font-brandon-medium);
  color: var(--raven-gray);
}

.qcvalue {
  font-size: 1.2vw;
  font-family: var(--font-brandon-regular);
  color: var(--raven-gray);
}

.height_25 {
  height: 25px;
}

.laundry_batch_icon svg {
  width: 144px;
  height: 177px;
}

.laundry_batch_icon svg path {
  fill: #545960;
}

.w_8rem {
  width: 8rem;
}

.vendors {
  width: calc(100% / 4);
  padding: 0px 40px;
  margin: 15px 0px;
}

.vendors_btn {
  width: 100%;
  padding: 0.8rem 0.7rem;
  color: var(--raven-gray);
  border: 1px solid var(--raven-gray);
  cursor: pointer;
  line-height: initial;
  text-align: center;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.limages {
  height: 14vw;
  width: 15vw;
} */

.limages {
  /* object-fit: contain;
  height: 100%; */
  width: 15vw;
  max-height: 100%;
}

/* laundry start */

.select_vendor_icon {
  position: absolute;
  left: -30px;
}

.select_vendor_icon svg {
  width: 65px;
  height: 42px;
}

.laundry_status:hover svg path {
  fill: var(--raven-red);
}

.laundry_status:hover {
  color: var(--raven-red);
}

.laundry_batch_icon {
  border: 1px solid transparent;
  padding: 4px;
}

.laundry_batch_icon:hover {
  border: 1px solid var(--raven-gray);
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .vendors {
    width: calc(100% / 3);
  }
}

.product_tier_image {
  width: calc(100% / 4) !important;
}

.tierimg {
  height: 113px;
}

.select_tier_popup {
  height: 70vh;
  overflow: auto;
}
