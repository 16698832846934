.imagage_block {
  display: block;
  z-index: 9999;
}

.imagage_block img {
  width: 100%;
  height: 90vh;
  background: white;
}

.fournotfour {
  font-family: var(--font-brandon-bold);
  line-height: 1;
  color: #000000AD;
  font-size: 11vw;
}

.pagenotfound {
  color: #000000AD;
  font-size: 2vw;
  font-family: var(--font-brandon-medium);
}

.pagecontent {
  color: #000000AD;
  font-size: 1.3vw;
  font-family: var(--font-brandon-medium);
}