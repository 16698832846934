.blocks {
  width: 100% !important;
  padding: 0.7rem;
  height: 100%;
  color: var(--raven-gray);
  border: 1px solid var(--raven-gray);
  cursor: pointer;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  line-height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeblocks {
  width: 100%;
  padding: 0.7rem;
  height: 100%;
  cursor: pointer;
  background: var(--raven-gray);
  color: white;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  line-height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .blocks:hover {
    background: var(--raven-gray);
    color: white;
}

.activeblocks:hover {
    color: var(--raven-gray);
    border: 1px solid var(--raven-gray);
    background: white;
} */

.product_name {
  font-size: var(--font-size-30);
  font-family: var(--font-brandon-bold);
}

.selected_close_btn {
  top: -7px !important;
  right: 0px;
}

.selected_close_btn svg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgb(240, 240, 240);
  border: 1px solid white;
}

.closeblock {
  width: calc(100% / 5);
  line-height: normal;
}

.closeblock svg path:hover {
  fill: var(--raven-red);
}

@media (max-width: 1400px) {
  .closeblock {
    width: calc(100% / 3);
  }
}
