:root {
  /* Color */
  --color-white: #fff;
  --color-black: #000;
  --raven-gray: #545960;
  --lemon-meringue: #fbe8c1;
  --raven-red: #d20c14;
  --bg-color: #efefef7f;
  --form-label-color: #545960;
  --input-field-border: #545960b3;
  --table-header-bg-color: #e3e6e8;
  --table-heading-text-color: #767a7f;
  --table-body-border-color: #c6c6c6;
  --dashboard-link-border: #54596026;
  --label-color: #475969;
  --tab-color: #54596033;
  --tab-text-color: #545960;
  --active_tab-color: #545960;
  --shimpment_text_color: #54596099;
  --place_holder_color: #54596080;
  --sort_success_bg: #fbfbf2;
  --icon-green: #789070;

  /* Font-size */
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-22: 1.375rem;
  --font-size-24: 1.5rem;
  --font-size-26: 1.625rem;
  --font-size-28: 1.75rem;
  --font-size-30: 1.875rem;
  --font-size-32: 2rem;
  --font-size-33: 2.063rem;
  --font-size-40: 2.5rem;

  /* Font-family */
  --font-brandon-regular: "Brandon Grotesque, Regular";
  --font-brandon-medium: "Brandon Grotesque, Medium";
  --font-brandon-bold: "Brandon Grotesque, Bold";

  /* Line-height */
  --line-height-26: 1.625rem;
  --line-height-29: 1.8125rem;
  --line-height-31: 1.9375rem;
  --line-height-37: 2.3125rem;
  --line-height-46: 2.875rem;
  --line-height-48: 3rem;
}

@font-face {
  font-family: "Brandon Grotesque, Regular";
  src: url(/src/static/fonts/brandon/Brandon_reg.otf);
}

@font-face {
  font-family: "Brandon Grotesque, Medium";
  src: url(/src/static/fonts/brandon/Brandon_med.otf);
}

@font-face {
  font-family: "Brandon Grotesque, Bold";
  src: url(/src/static/fonts/brandon/Brandon_bld.otf);
}

.text-gray {
  color: var(--raven-gray) !important;
}

.text-red {
  color: var(--raven-red) !important;
}

.font16px {
  font-size: var(--font-size-16);
}

.font20px {
  font-size: var(--font-size-20);
}

.font_Brandon_regular {
  font-family: "Brandon Grotesque, Regular";
}

.font_Brandon_medium {
  font-family: "Brandon Grotesque, Medium";
}

.width_maxcontent {
  width: max-content;
}

a:hover {
  color: var(--raven-red);
}

.bg_color_raven_gray {
  background-color: var(--bg-color);
}
.bg_color_raven_red{
  background-color: var(--raven-red);
}

.height45 {
  height: 45px !important;
}

.min-h45 {
  min-height: 45px;
}

.h-70 {
  height: 70%;
}

.h-30 {
  height: 30%;
}

.h-90 {
  height: 90%;
}

.w-31 {
  width: 31%;
}

.min-height-50 {
  min-height: 50px;
}

.w-60 {
  width: 60%;
}

.w-10{
  width: 10%;
}
.w-24 {
  width: 24% !important;
}

.w-45 {
  width: 45%;
}

.left_22 {
  left: 22px;
}

.max-content-height {
  height: max-content;
}
.w-200px{
  width: 200px;
  max-width: 200px;
}
.z-index-1 {
  z-index: 1;
}
.error_border_color {
  border: var(--raven-red) solid 1px;
}

.mw-50{
  max-width: 50%;
}
