.file-uploader {
  border: 2px dashed #aaa;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.file-uploader.dragging {
  background-color: #f7f7f7;
}

/* Hide the default file input button */
input[type="file"] {
  display: none;
}

/* Style the custom button */
.custom-file-input {
  display: inline-block;
  position: relative;
}

.custom-file-input label {
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

/* Optional: Style the label to look like a button */
.custom-file-input label:hover {
  background-color: #2980b9;
}

.medialibraryfoldername {
  font-size: 0.9vw;
}

.media_folder_box:hover,
.folder_img_inner:hover {
  box-shadow: 0px 0px 7px 1px #0000009c;
  -webkit-transform: scale(1.02);
  transform: scale(1.01);
  transition: all 0.5s;
}

.media_folder_box:hover svg path {
  fill: var(--raven-red);
}

.media_folder_box:hover .edit_folder {
  color: var(--raven-red);
}

.media_folder {
  top: -12px;
  right: -10px;
}

.mediabox .media_folder {
  top: -26px;
}

.medialibrary_popup {
  height: 75vh !important;
}

.mediafoldericon svg {
  height: 3.438rem;
  width: 3.438rem;
}

.media_folder svg {
  width: 20px;
  height: 20px;
}

.media_folder:hover svg path {
  fill: var(--raven-red);
}

.mediacreatbtn svg {
  width: 20px;
  margin-right: 10px;
  height: 20px;
}

.mediacreatbtn svg path,
.media_upload svg path {
  fill: white;
}

.mediacreatbtn:hover svg path {
  fill: var(--raven-red);
}

.media_upload:hover svg path {
  fill: var(--raven-gray);
}

.edit_folder input {
  min-height: unset !important;
}

.edit_folder {
  cursor: text;
}

.edit_folder span {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.mediasearch {
  height: 50px !important;
  max-width: 340px;
  min-width: 150px;
}

.folder_img svg:hover path {
  fill: var(--raven-red);
}
.folder_img:hover span {
  color: var(--raven-red);
}

.mediaheader {
  position: sticky;
  top: calc(103px + 2.063vw);
  background: white;
  z-index: 1;
  padding-top: 10px;
}

.mediabox {
  height: 50vh;
  overflow-y: auto;
}
.mediabox .edit_folder input {
  font-size: initial !important;
}

.mediaboxwithoutmodal {
  min-height: 70vh;
  height: calc(100vh - 240px);
  overflow: auto;
}

.mediabox .media_folder_box_modal,
.mediabox .folder_img {
  height: 9rem;
  width: calc(100% / 7);
}

.media_folder_box_modal,
.folder_img {
  min-height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 6);
  padding: 0.6rem 1.2rem !important;
}

.media_folder_box_modal_inner:hover,
.folder_img_inner:hover {
  transform: scale(1.02);
  transition: all 0.5s;
  box-shadow: 0px 0px 7px 1px #0000009c !important;
}

.media_folder_box_modal_inner:hover div,
.media_folder_box_modal_inner:hover .mediafoldericon svg path {
  fill: var(--raven-red);
  color: var(--raven-red);
}

.folder_img img {
  object-fit: contain;
  height: 5.35rem;
  min-height: 5.35rem;
}

.folder_img svg {
  height: 20px;
  width: 20px;
}

.img_close {
  top: -12px;
  right: -9px;
  cursor: pointer;
}

.mediabox .img_close {
  top: -26px;
}

.mediafolderpath {
  font-size: var(--font-size-22);
  font-family: var(--font-brandon-medium);
}

.createmediapopupbody {
  display: flex !important;
}

.pathtext {
  max-width: 200px;
}

.pathtext:last-child {
  background: var(--raven-red);
  color: white;
}

.progressbar {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1;
}

@media (max-width: 1400px) {
  .media_folder_box_modal,
  .folder_img {
    width: calc(100% / 5);
  }

  .mediaheader {
    position: sticky;
    top: calc(90px + 2.063vw);
    background: white;
    z-index: 1;
    padding-top: 10px;
  }

  .mediabox .media_folder_box_modal,
  .mediabox .folder_img {
    height: 7rem;
    width: calc(100% / 6);
  }

  .mediaboxwithoutmodal {
    height: calc(100vh - 210px);
  }
}

@media (max-width: 1200px) {
  .media_folder_box_modal,
  .folder_img {
    min-height: 9rem;
  }

  .folder_img img {
    height: 4.35rem;
    min-height: 4.35rem;
  }

  .mediabox .media_folder_box_modal,
  .mediabox .folder_img {
    width: calc(100% / 5);
  }

  .mediaboxwithoutmodal {
    height: calc(100vh - 200px);
  }
}

.file_input svg path {
  fill: white;
}

.file_input {
  max-width: 200px;
  width: 200px;
}

.import_error_text {
  width: 200px;
  font-size: 0.8rem;
  text-align: center;
}
