/* common css starts here */

/* Common colours */
.text-color {
    color: #073763;
}

.dark-color {
    color: #292929;
}

.background-indii-blue {
    background-color: #073763;
}

.background-green {
    background-color: #73ab4e;
}

.background-red {
    background-color: #FF0000;
}

.background-blue {
    background-color: #0070C0;
}

.text-blue {
    color: #0070C0;
}

.text-green {
    color: #73ab4e;
}

.text-red {
    color: var(--raven-red);
}


/* Icons styles */
.logo {
    width: 102px;
    height: 29px;
}

.header-icon {
    width: 23px;
    height: 23px;
}

.profile-icon {
    width: 3.5rem;
    height: 3.5rem;
    cursor: pointer;
    object-fit: cover;
}

.cancel-icon {
    width: 16px;
    height: 16px;
    margin-left: 80px !important;
}

.shortcut-icon {
    width: 35px;
    height: 35px;
}

.dashboard-icon {
    width: 5rem;
    height: 8rem;
}

.box {
    width: 20px;
    height: 20px;
}


/* Dynamic content part */
.right-container {
    width: 93%;
    display: inline-flex;
    justify-content: center;
}


/* Dashboard css */
.display-linebreak {
    white-space: pre-line;
}


/* Navbar css */
.navbar-brand {
    margin-right: 1.8rem !important;
}



/* Sidebar css */
.side-bar {
    min-width: 7% !important;
    height: 91vh;
    overflow: hidden;
}

.side-bar-open {
    position: absolute;
    background-color: white;
    z-index: 1;
    /* transition: right 100s ease-in-out; */
}

.no-padd {
    padding: 0px !important;
}

.sidebar-moving {
    /* transition: right 100s ease-in-out; */
    animation: mymove 1s alternate;
    /* animation-timing-function: ease-out;
    animation-iteration-count: 1; */
}

@keyframes mymove {
    from {
        margin-left: -100px;
    }

    to {
        margin-left: 2px;
    }
}



/* Language dropdown css */
#language-font {
    font-size: 1.2rem;
    margin-bottom: 6px !important;
}

.language-dropdown div {
    border: 0;
    margin-bottom: 0px;
    font-size: 1.25rem;
    background: transparent;
    color: #efc3c3;
}

.language-dropdown div div {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-bottom: 0px !important;
    margin-top: 2px !important;
}



/* Content menu css */
.account-menu {
    position: absolute;
    right: 12px;
    top: 75%;
    z-index: 1;
}

.shortcut-menu {
    position: absolute;
    right: 30%;
    top: 75%;
    z-index: 1;
}

.notification-menu {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1;
}


/* Message board css */
.employee-message {
    background-color: #73ab4e38;
}

.dimona-message {
    background-color: #FF000038;
}

.indii-message {
    background-color: #0070C038;
}

.carousel .thumbs-wrapper {
    display: none;
}