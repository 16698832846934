.sidenav {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 3px 8px;
}

header .navbar {
  background-color: var(--raven-red) !important;
  height: 5rem;
}

.raven-logo {
  width: fit-content;
  height: 4rem;
}

.my-rofile-icon {
  height: 22px;
  width: 20px;
}

.profile_img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.edit_profile_btn {
  width: 8px;
}

.edit_profile_img {
  bottom: -30px;
  right: 0px;
  height: 30px;
  background: red;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.edit_profile_img1 {
  bottom: 0px;
  right: 0px;
  height: 1.4vw;
  width: 1.4vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.language_dropdown {
  background: transparent;
  border: none !important;
  color: #FFFFFFB3;
  font-size: var(--font-size-18);
}

.language_dropdown option {
  color: black;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.language_dropdown:focus-visible {
  border: none !important;
}

select:focus {
  outline: none;
}

.myaccount {
  font-family: "Brandon_regular";
  font-size: var(--font-size-16);
}

.popover {
  transform: unset !important;
  right: 28px !important;
  width: unset !important;
  left: unset !important;
  max-width: 100%;
  top: 80px !important;
  position: fixed !important;
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
  right: -30px !important;
}

.sidetabActive svg path,
.sidetabActive ellipse {
  fill: red !important;
}

.sidetab {
  background: none;
}

.quickaccessbody{
  padding: 0px;
  height: 60vh;
  overflow-y: scroll;
}
.quickasscessmodalcontent .modal-dialog{
  max-width: 80vw !important;
}

@media (max-width:1400px) {
  header .navbar {
    height: calc(6rem*1400/1920);
  }
}